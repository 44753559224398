<template>
    <nuxt-link
        v-if="item && header"
        :to="getLink(item)"
        class="image-card background--brown"
    >
        <ImageAsset
            v-if="image && !videoUrl"
            class="image-card__image"
            :image="image"
            sizes="353px"
            alt=""
        />
        <LazyBaseVideo
            v-if="videoUrl"
            ref="video"
            :video-url="videoUrlMobile || videoUrl"
            :video-url-mobile="videoUrlMobile"
            :poster="''"
            :autoplay="true"
            :show-button="false"
            class="image-card__video"
        />
        <div class="image-card__background" />
        <div class="image-card__text">
            <p
                v-if="category"
                class="image-card__tag"
            >
                {{ category }}
            </p>
            <p
                v-if="header"
                class="image-card__header"
            >
                {{ header }}
            </p>
        </div>
    </nuxt-link>
</template>

<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],

    props: {
        item: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },

    computed: {
        header() {
            return this?.item?.title ?? '';
        },
        category() {
            if (this?.item?.generalCategory) {
                return this?.item?.generalCategory;
            }
            return this?.item?.blogCategory?.[0]?.title ?? '';
        },
        image() {
            const imageCard = this?.item?.imageCard?.[0] ?? null,
                imageMobile = this?.item?.imageMobile?.[0] ?? null;

            return imageCard ?? imageMobile ?? this?.item?.imageForCard?.[0] ?? null;
        },
        videoUrl() {
            return this?.item?.videoUrl ?? '';
        },
        videoUrlMobile() {
            return this?.item?.videoUrlMobile ?? '';
        }
    }
};
</script>

<style lang="less" src="./ImageCard.less"></style>
